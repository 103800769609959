<template>
  <div>
    <div style="margin: 15px">
      <h4>{{ $t("sms.nombreMessage") }}</h4>
      <div class="text-uppercase">
        {{ $t("sms.nomberTotal") }} : {{ total }}
      </div>
    </div>

    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom"
      :options="options"
      :series="series"
      :height="250"
      type="line"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["data"],
  data() {
    return {
      options: {},
      series: [],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
      total: 0,
    };
  },
  watch: {
    data: function (value) {
      this.updateChart();
    },
  },
  created() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      this.total = 0;
      const maxDate = moment.max(
        this.data.map((message) => moment(message.createdAt))
      );
      const minDate = moment.min(
        this.data.map((message) => moment(message.createdAt))
      );

      const days = [];
      const messageSerie = [];
      let currDate = minDate.startOf("day");
      let lastDate = maxDate.startOf("day");

      days.push(currDate.format("DD MMMM"));
      let nb = 0;

      this.data.forEach((message) => {
        if (
          new Date(message.createdAt).getDate() == currDate.date() &&
          new Date(message.createdAt).getMonth() == currDate.month() &&
          new Date(message.createdAt).getFullYear() == currDate.year()
        )
          nb += message.totalSMSRSent;
      });

      messageSerie.push(nb);
      this.total += nb;

      while (currDate.add(1, "days").diff(lastDate) <= 0) {
        days.push(currDate.format("DD MMMM"));
        let nb = 0;

        this.data.forEach((message) => {
          if (
            new Date(message.createdAt).getDate() == currDate.date() &&
            new Date(message.createdAt).getMonth() == currDate.month() &&
            new Date(message.createdAt).getFullYear() == currDate.year()
          )
            nb += message.totalSMSRSent;
        });

        messageSerie.push(nb);
        this.total += nb;
      }
      this.updateOptions(days);
      this.updateSeries(messageSerie);
    },
    updateSeries(messageSerie) {
      this.series = [
        {
          name: "messages",
          data: messageSerie,
          color: "#33cc33",
        },
      ];
    },
    updateOptions(days) {
      this.options = {
        chart: {
          height: 250,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          color: this.colors,
        },
        labels: days[0],

        xaxis: {
          type: "string",
          categories: days,
          labels: {
            show: false,
            style: {
              colors: this.labelColor,
              fontSize: "12px",
            },
          },
          title: {
            text: this.$t("sms.jours"),
            offsetX: 0,
            offsetY: -10,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {},
        },
        fill: {
          type: "gradient",
          gradient: {
            stops: [0, 100],
          },
        },
        color: this.colors,
        markers: {
          color: this.colors,
          strokeColor: this.lightColors,
          strokeWidth: 3,
        },
      };
    },
  },
};
</script>
