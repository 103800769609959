<template>
  <div>
    <div style="margin: 15px">
      <h5>{{ $t("sms.nombreMessageParCategorie") }}</h5>
    </div>

    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom"
      :options="options"
      :series="series"
      :height="300"
      type="bar"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      options: {},
      series: [],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E", "#feb019"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    };
  },
  watch: {
    data: function (value) {
      this.updateChart();
    },
  },
  created() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      const tags = {};
      const tagsSerie = [];
      const data = [];

      this.data.forEach((message) => {
        message.tags.forEach((tag) => {
          if (!tags[tag]) tags[tag] = message.totalSMSRSent;
          else tags[tag] += message.totalSMSRSent;
        });
      });

      for (const tag of Object.keys(tags)) {
        tagsSerie.push(tag);
        data.push(tags[tag]);
      }

      this.updateSeries(data);
      this.updateOptions(tagsSerie, data);
    },
    updateSeries(data) {
      this.series = [
        {
          name: this.$t("sms.nombreMessage"),
          type: "bar",
          data,
        },
      ];
    },
    updateOptions(tagsSerie, data) {
      this.options = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: tagsSerie,
          labels: {
            style: {
              fontSize: "13px",
            },
          },
        },
        yaxis: {
          min: 0,
          max: Math.max(...data) + 3,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      };
    },
  },
};
</script>
